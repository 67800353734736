<template>
	<div class="container">
		<div class='resetPassword'>
			<h2 class="text-dark mx-auto my-5">Reset Password</h2>

			<b-card>
				<b-form @submit.prevent>
					<b-form-group
						label="Current Password"
						label-for="input-password"
						class="mb-3"
					>
						<b-form-input
							id="currentPassword"
							v-model="currentPassword"
							name="currentPassword"
							type="password"
							auto-complete="current-password"
							placeholder="Current Password"
							required
						/>
					</b-form-group>
					<b-form-group
						label="New Password"
						label-for="input-password"
						class="mb-3"
					>
						<b-form-input
							id="newPassword"
							v-model="newPassword"
							name="newPassword"
							type="password"
							auto-complete="new-password"
							placeholder="New Password"
							required
						/>
					</b-form-group>
					<b-form-group
						label="Confirm New Password"
						label-for="input-password"
						class="mb-3"
					>
						<b-form-input
							id="confirmNewPassword"
							v-model="confirmNewPassword"
							name="confirmNewPassword"
							type="text"
							auto-complete="confirm-new-password"
							placeholder="Re-enter New Password"
							required
						/>
					</b-form-group>
					<b-button
						variant="primary"
						block
						:disabled="canSubmit || isResetPasswordLoader"
						@click="reAuthenticateCredential(currentPassword, newPassword,confirmNewPassword)"
					>
						<template v-if="isResetPasswordLoader">
										<b-spinner small type="grow"></b-spinner>
										Resetting...
									</template>
									<template v-else>
										Reset Password
									</template>
					</b-button>
					<div v-if="passwordReset" class="success-msg">Password updated successfully</div>
					<div v-else class="error">{{ error }}</div>
					<div class="back-to-profile"><b-link @click="$router.push({ name: 'profile' })">
						<b-icon-arrow-left-circle class="arrow-icon"></b-icon-arrow-left-circle>
						Back to Profile
						</b-link></div>
				</b-form>
			</b-card>
		</div>
	</div>
</template>
<script>
import { auth } from '../firebase';
import firebase from 'firebase/app';
import { BIconArrowLeftCircle } from 'bootstrap-vue';

export default {
	name:'ResetPassword',
	components:{BIconArrowLeftCircle},
	data() {
		return {
			email: '',
			passwordReset: false,
			error: null,
			currentPassword: '',
			newPassword: '',
			isResetPasswordLoader: false,
		};
	},
	computed: {
		canSubmit() {
			return !(this.newPassword && this.newPassword.length >= 6);
		},
	},
	methods: {
		
	reAuthenticateCredential(currentPassword, newPassword, confirmNewPassword) {
		const user = auth.currentUser;
		const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
		auth.currentUser.reauthenticateWithCredential(cred)
		.then(() =>{
			if(newPassword==confirmNewPassword){
				this.isResetPasswordLoader= true;
				this.changePassword(newPassword);
			}else{
				this.passwordReset = false;
				this.error = "The password confirmation does not match";
			}
		})
		.catch((error) => {
			this.passwordReset = false;
			this.error = "The current password is invalid or the user does not have a password.";
			console.error(error.message)
		});
		
	},

	changePassword(newPassword) {
		this.isResetPasswordLoader = true;
		auth.currentUser.updatePassword(newPassword)
		.then(() => {
			this.passwordReset = true;
			this.isResetPasswordLoader = false;
			// console.log("Password updated successfully")
			// this.$store.dispatch('logout').then(() => {
			// 	if (location.pathname !== '/trial')
			// 		this.$router.push({ path: '/login' });
			// });
		})
		.catch((error) => {
			this.isResetPasswordLoader = false;
			this.passwordReset = false;
			this.error = error.message;
			console.error("Error::", error.message)
		});
	}
	},
};
</script>
<style scoped>
.container{
	max-width: 500px;
}
.success-msg{
	padding-top: 10px;
	color: green;
}
.error{
	padding-top: 10px;
	color: red;
}
.back-to-profile{
	text-align: center;
    padding-top: 10px;
}
.arrow-icon{
    padding-right: 3px;
}
</style>